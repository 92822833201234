
      export interface PossibleTypesResultData {
        possibleTypes: {
          [key: string]: string[]
        }
      }
      const result: PossibleTypesResultData = {
  "possibleTypes": {
    "Account": [
      "Group",
      "InternalService",
      "Person",
      "Team"
    ],
    "AccountInviteWithPossibleError": [
      "AccountInvite",
      "ActionNotAllowedError"
    ],
    "AccountManagerWithPossibleError": [
      "AccountManager",
      "ActionNotAllowedError"
    ],
    "AdvanceStrategy": [
      "AtLeastPlacementAdvanceStrategy",
      "ExactPlacementAdvanceStrategy"
    ],
    "BreakStrategy": [
      "FixedNumberBreakStrategy",
      "MaximumParticipantsBreakStrategy"
    ],
    "ChallengeSpecificDetails": [
      "LadderSpecificDetails"
    ],
    "ClientVisibleError": [
      "ActionNotAllowedError"
    ],
    "Competition": [
      "Challenge",
      "MatchUp",
      "Tournament"
    ],
    "CompetitionMatch": [
      "MatchUpMatchBye",
      "PlayableMatchUpMatch",
      "PlayableTournamentMatch",
      "TournamentMatchBye"
    ],
    "CompetitionSpecificDetails": [
      "BattleRoyaleSpecificDetails",
      "KingOfTheHillSpecificDetails",
      "LadderSpecificDetails",
      "PrunedDoubleEliminationSpecificDetails",
      "PrunedSingleEliminationSpecificDetails",
      "SingleEliminationSpecificDetails"
    ],
    "Cost": [
      "RealWorldCurrencyCost"
    ],
    "CurrencyItemCost": [
      "ExternalCurrencyItemCost",
      "SwarmioCurrencyItemCost"
    ],
    "CurrencyItemCostArchive": [
      "ExternalCurrencyItemCostArchive",
      "SwarmioCurrencyItemCostArchive"
    ],
    "DateAttributeWithPossibleError": [
      "ActionNotAllowedError",
      "DateAttribute"
    ],
    "EventEntryRules": [
      "TeamEventEntryRules"
    ],
    "EventTeamMember": [
      "BaseEventTeamMember",
      "TechnicalParticipant"
    ],
    "ExternalSubscriptionPackage": [
      "SLTProviderExternalSubscriptionPackage"
    ],
    "FixedPrize": [
      "CustomPrize",
      "SwarmioCurrencyPayoutPrize"
    ],
    "GameSpecificCompetitionDetails": [
      "CSGOCompetitionDetails",
      "CSGOMatchDetails"
    ],
    "GameSpecificDetails": [
      "CSGOCompetitionDetails",
      "CSGOMatchDetails"
    ],
    "GamingRole": [
      "GamingFullRole",
      "GamingRoleFragment"
    ],
    "GroupInviteWithPossibleError": [
      "ActionNotAllowedError",
      "GroupInvite"
    ],
    "GroupTrait": [
      "Group",
      "Team"
    ],
    "HiveContact": [
      "NewHiveContact",
      "UpdateHiveContactRequest",
      "VerifiedHiveContact"
    ],
    "HiveContactWithPossibleError": [
      "ActionNotAllowedError",
      "NewHiveContact",
      "UpdateHiveContactRequest",
      "VerifiedHiveContact"
    ],
    "IdentityProviderWithPossibleError": [
      "ActionNotAllowedError",
      "IdentityProvider"
    ],
    "IndividualParticipant": [
      "ReadyIndividualParticipant",
      "UnreadyIndividualParticipant"
    ],
    "ManagedAccountWithPossibleError": [
      "ActionNotAllowedError",
      "ManagedAccount"
    ],
    "MatchUpFormatSpecificDetails": [
      "BattleRoyaleSpecificDetails",
      "KingOfTheHillSpecificDetails"
    ],
    "MatchUpMatch": [
      "MatchUpMatchBye",
      "PlayableMatchUpMatch"
    ],
    "MediaTrait": [
      "MediaTwitchStreamType"
    ],
    "MissingParticipantRequirement": [
      "EnumMissingParticipantRequirement",
      "NotEnoughTeamMembers"
    ],
    "ObjectRepresentation": [
      "Group",
      "InternalService",
      "MediaTwitchStreamType",
      "Person",
      "PrivateGameServer",
      "PublicGameServer",
      "StoreMerchantPaymentMethod",
      "SwarmioCurrency",
      "SwarmioPayPayment",
      "Team"
    ],
    "OutOfContextCompetitionMatches": [
      "OutOfContextMatchUpMatches",
      "OutOfContextTournamentMatches"
    ],
    "Participant": [
      "ReadyIndividualParticipant",
      "TeamParticipant",
      "UnreadyIndividualParticipant"
    ],
    "ParticipantReference": [
      "CompetitionParticipantReference",
      "CompetitionParticipantReferenceWithRoster",
      "EventStageParticipantReference"
    ],
    "PhasedCompetitionFormatSpecificDetails": [
      "BattleRoyaleSpecificDetails",
      "KingOfTheHillSpecificDetails",
      "PrunedDoubleEliminationSpecificDetails",
      "PrunedSingleEliminationSpecificDetails",
      "SingleEliminationSpecificDetails"
    ],
    "Prize": [
      "CustomPrize",
      "SwarmioCurrencyPayoutPrize",
      "VariablePrize"
    ],
    "PrizePayoutReceipt": [
      "CustomPrizePayoutReceipt",
      "SwarmioCurrencyFixedPrizePayoutReceipt"
    ],
    "Result": [
      "BasicResult",
      "ManualResult",
      "MultiDimensionalBucketBasedResult"
    ],
    "SLTExternalSubscriptionPackage": [
      "SLTProviderExternalSubscriptionPackage"
    ],
    "Server": [
      "PrivateGameServer",
      "PublicGameServer"
    ],
    "Stream": [
      "TwitchStream",
      "YouTubeStream",
      "YouTubeVideo"
    ],
    "StreamingEvent": [
      "AccountNotificationEvent",
      "ClientNotificationEvent"
    ],
    "Streams": [
      "MediaTwitchStreamType"
    ],
    "StringAttributeWithPossibleError": [
      "ActionNotAllowedError",
      "StringAttribute"
    ],
    "SubscriptionProviderAccount": [
      "SLTProviderAccount"
    ],
    "SwarmioSubscription": [
      "ExplicitSubscription",
      "ImplicitSubscription"
    ],
    "SwarmioVoucher": [
      "IntegratedVoucher",
      "NonIntegratedVoucher"
    ],
    "SwarmioWalletTransactionWithMetadata": [
      "BoughtTransaction",
      "CashOutTransaction",
      "ExchangedTransaction",
      "FreebieTransaction",
      "TransferTransaction"
    ],
    "TournamentMatch": [
      "PlayableTournamentMatch",
      "TournamentMatchBye"
    ],
    "TournamentSpecificDetails": [
      "PrunedDoubleEliminationSpecificDetails",
      "PrunedSingleEliminationSpecificDetails",
      "SingleEliminationSpecificDetails"
    ],
    "UploadTrait": [
      "MediaTwitchStreamType"
    ],
    "User": [
      "InternalService",
      "Person"
    ],
    "VariablePrizeCondition": [
      "SpecificSubscriptionPrizeCondition",
      "SubscriptionPrizeCondition"
    ],
    "WithUnverifiedHiveContact": [
      "NewHiveContact",
      "UpdateHiveContactRequest"
    ]
  }
};
      export default result;
    